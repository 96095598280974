<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import {
	AccordionHeader,
	AccordionTrigger,
	type PrimitiveProps,
} from 'radix-vue';
import { PhCaretDown } from '@phosphor-icons/vue';
import { cn } from '@laam/lib/utils';
import { cva, type VariantProps } from 'class-variance-authority';

const accordionTriggerVariants = cva(
	/*tw*/ 'flex flex-1 items-center justify-between font-medium transition-all [&[data-state=open]>svg]:rotate-180 rounded-xl ',

	{
		variants: {
			size: {
				lg: /*tw*/ 'p-3xl',
				md: /*tw*/ 'px-2xl py-lg gap-md',
				sm: /*tw*/ 'px-xl py-md gap-md',
			},
			border: {
				none: '',
				solid: 'border border-solid border-gray-200',
			},
			defaultVariants: {
				size: 'md',
			},
		},
	},
);

export type AccordionTriggerVariants = VariantProps<
	typeof accordionTriggerVariants
>;

interface Props extends PrimitiveProps {
	class?: HTMLAttributes['class'];
	size?: AccordionTriggerVariants['size'];
	border?: AccordionTriggerVariants['border'];
	active?: boolean;
	roundedOnActive?: boolean;
	showCaret: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	size: 'md',
	class: '',
	border: 'none',
	active: false,
	roundedOnActive: true,
	showCaret: true,
});

const delegatedProps = computed(() => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { class: _, ...delegated } = props;

	return delegated;
});
</script>

<template>
	<AccordionHeader as="div" class="flex">
		<AccordionTrigger
			v-bind="delegatedProps"
			:class="[
				cn(accordionTriggerVariants({ size, border }), props.class),
				{
					'border-gray-800 ': props.active,
					'rounded-b-none': !roundedOnActive,
				},
			]"
		>
			<div class="flex items-center">
				<slot name="leftIcon" />
				<slot />
			</div>
			<slot name="icon">
				<PhCaretDown
					v-if="showCaret"
					class="h-3xl w-3xl shrink-0 transition-transform duration-200"
				/>
			</slot>
		</AccordionTrigger>
	</AccordionHeader>
</template>
